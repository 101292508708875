/* eslint-disable jsx-a11y/label-has-associated-control */
import { TrashIcon } from '@heroicons/react/20/solid';
import {
  CheckboxInput,
  ErrorMessage,
  LoadingOrError,
  SubmitButton,
} from '@retainerclub/common';
import {
  useCart,
  useFormValues,
  useLocale,
  usePolicy,
} from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

function CheckoutCart({
  error = null,
  mutationErrors = null,
  loading = false,
  ctError = null,
  ctLoading = false,
}) {
  const { cart, cLoading, cError, destroyCartItem } = useCart();
  const { setPolicyAndOpenModal } = usePolicy();

  const { locale } = useLocale();
  const {
    formState: { isSubmitting, isValid, isLoading },
  } = useFormContext();

  const { acceptedTerms } = useFormValues();

  const subTotal = useMemo(
    () => cart?.cartItemsAttributes?.reduce((acc, item) => acc + item.price, 0),
    [cart?.cartItemsAttributes],
  );

  const shipping = cart?.shippingAmount || 0;

  const taxes = 0;

  const total = useMemo(
    () => subTotal + shipping + taxes,
    [shipping, subTotal],
  );

  if (cLoading || cError || !cart?.cartItemsAttributes) {
    return <LoadingOrError loading={cLoading} error={cError} />;
  }

  return (
    <div className="mt-10 lg:mt-0">
      <h2 className="font-medium text-gray-900 text-lg">Order summary</h2>

      <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
        <h3 className="sr-only">Items in your cart</h3>
        <ul className="divide-y divide-gray-200">
          {cart.cartItemsAttributes.map((cartItem) => (
            <li key={cartItem.id} className="flex px-4 py-6 sm:px-6">
              <div className="shrink-0">
                <img
                  src={cartItem.product.featuredImage}
                  alt={cartItem.product.name}
                  className="w-20 rounded-md"
                />
              </div>

              <div className="ml-6 flex flex-1 flex-col">
                <div className="flex">
                  <div className="min-w-0 flex-1">
                    <h4 className="font-medium text-base">{cartItem.name}</h4>
                    <p className="mt-1 text-gray-500 text-xs">
                      {cartItem.product.description}
                    </p>
                    <p className="mt-1 text-gray-500 text-xs">
                      {cartItem.patient ? (
                        <span>
                          Purchasing For:&nbsp;
                          <span className="font-bold">
                            {cartItem?.patient?.firstName}
                          </span>
                        </span>
                      ) : null}
                    </p>
                  </div>

                  <div className="ml-4 flow-root shrink-0">
                    <button
                      type="button"
                      className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                      onClick={() => destroyCartItem(cartItem.id)}
                    >
                      <span className="sr-only">Remove</span>
                      <TrashIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <div className="flex flex-1 items-end justify-between pt-2">
                  <div className="text-sm">Qty: {cartItem.quantity}</div>

                  <p className="mt-1 ml-4 font-medium text-gray-900 text-sm">
                    {(cartItem.price / 100).toLocaleString(locale, {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <dl className="space-y-6 border-gray-200 border-t px-4 py-6 sm:px-6">
          <div className="flex items-center justify-between">
            <dt className="text-sm">Subtotal</dt>
            <dd className="font-medium text-gray-900 text-sm">
              {(subTotal / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
          <div className="flex items-center justify-between">
            <dt className="text-sm">Shipping</dt>
            <dd className="font-medium text-gray-900 text-sm">
              {(shipping / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
          <div className="flex items-center justify-between">
            <dt className="text-sm">Taxes</dt>
            <dd className="font-medium text-gray-900 text-sm">
              {(taxes / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
          <div className="flex items-center justify-between border-gray-200 border-t pt-6">
            <dt className="font-medium text-base">Total</dt>
            <dd className="font-medium text-base text-gray-900">
              {(total / 100).toLocaleString(locale, {
                style: 'currency',
                currency: 'USD',
              })}
            </dd>
          </div>
        </dl>

        <div className="border-gray-200 border-t px-4 py-6 sm:px-6">
          {error && (
            <div className="col-span-full my-4">
              <ErrorMessage error={error} />
            </div>
          )}

          {ctError && (
            <div className="col-span-full my-4">
              <ErrorMessage error={ctError} />
            </div>
          )}

          {mutationErrors && (
            <div className="col-span-full my-4">
              <ErrorMessage errorMessages={mutationErrors} />
            </div>
          )}

          <div className="col-span-full mb-6">
            <CheckboxInput
              name="acceptedTerms"
              label="Terms and Conditions"
              description={
                <span>
                  By checking this box, you agree to Retainer Club&apos;s
                  &apos;s&nbsp;
                  <button
                    type="button"
                    className="text-rcprimary-400 hover:text-rcprimary-300"
                    onClick={() => setPolicyAndOpenModal('terms')}
                  >
                    Terms and Conditions
                  </button>
                  ,&nbsp; shipping and return policies.
                </span>
              }
            />
          </div>

          <SubmitButton
            isLoading={isLoading || loading || ctLoading}
            isSubmitting={isSubmitting}
            disabled={!acceptedTerms}
            isValid={isValid}
            label="Place Order"
            full
            notRounded
          />
        </div>
      </div>
    </div>
  );
}

CheckoutCart.propTypes = {
  error: PropTypes.object,
  mutationErrors: PropTypes.array,
  loading: PropTypes.bool,
  ctError: PropTypes.object,
  ctLoading: PropTypes.bool,
  acceptTerms: PropTypes.bool,
  setAcceptTerms: PropTypes.func,
};

export default CheckoutCart;
