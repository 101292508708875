import { InformationCircleIcon, UserIcon } from '@heroicons/react/20/solid';

// eslint-disable-next-line import/prefer-default-export
export const profileNavigation = [
  { id: 1, name: 'My Profile', to: '/profile', icon: UserIcon },
  {
    id: 2,
    name: 'Support',
    to: 'https://www.v2.retainerclub.com/support',
    icon: InformationCircleIcon,
  },
];
